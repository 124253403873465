// extracted by mini-css-extract-plugin
export var content = "CareersHero-module--content--faaba";
export var phoneStarLeft = "CareersHero-module--phoneStarLeft--0bb51";
export var phoneStarMain = "CareersHero-module--phoneStarMain--078ff";
export var phoneStarRight = "CareersHero-module--phoneStarRight--92d18";
export var star = "CareersHero-module--star--2226c";
export var star1 = "CareersHero-module--star1--435b4";
export var star10 = "CareersHero-module--star10--cbfe2";
export var star11 = "CareersHero-module--star11--a5c0d";
export var star12 = "CareersHero-module--star12--d6bcb";
export var star2 = "CareersHero-module--star2--3301e";
export var star3 = "CareersHero-module--star3--52868";
export var star4 = "CareersHero-module--star4--ffd28";
export var star5 = "CareersHero-module--star5--b1d33";
export var star6 = "CareersHero-module--star6--2937f";
export var star7 = "CareersHero-module--star7--65669";
export var star8 = "CareersHero-module--star8--b63dd";
export var star9 = "CareersHero-module--star9--91aac";
export var starLayer1 = "CareersHero-module--starLayer1--d864c";
export var starLayer2 = "CareersHero-module--starLayer2--34eff";
export var starLayer3 = "CareersHero-module--starLayer3--08451";
export var subtext = "CareersHero-module--subtext--e1d14";
export var wrapper = "CareersHero-module--wrapper--81d47";